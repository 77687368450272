export const colors = {
  grey: "#bbbbbb",
  mdGrey: "#787878",
  dkGrey: "#222222",
  offWhite: "#f2f2f2",
  white: "#FFFFFF",
  black: "#000000",
  orange: "#d27e36",
  gitHub: "#4183C4",
  linkedIn: "#0976B4",
  googlePlus: "#DD4B39",
  faceBook: "#3B5998",
  instagram: "#3F729B",
  danger: "#ff8080",
  success: "#5bcc89",
};

export default colors;
